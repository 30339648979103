@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+TC:wght@100..900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #010f1a;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5 {
  font-weight: bold !important;
}

.mainBanner {
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  color: #FFF;
}

.PlanCard, .circleCard {
  max-width: calc(33.33% - 10px);
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-size: cover;
  background-position: center;
  margin: 5px;
}

.FinTechCard {
  max-width: calc(25% - 10px);
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  margin: 5px;
}

.indexSection {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.ColorBG::after {
  position: absolute;
  display: block;
  content: "";
  width: 60%;
  height: 100%;
  right: 0;
  background-color: #cd6f00;
  z-index: -1;
}

.ColorBG_W::after {
  position: absolute;
  display: block;
  content: "";
  width: 60%;
  height: 100%;
  right: 0;
  background-color: #FFF;
  z-index: -1;
}

.marketing_Banner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 991px) {
  .indexSection {
    justify-content: center;
    margin-bottom: 2rem;
  }
  .indexSection .MuiGrid2-root{
    justify-content: center;
  }
  .PlanCard, .circleCard {
    max-width: calc(90% - 10px);
  }
  .ColorBG::after, .ColorBG_W::after {
    display: none;
  }
  .marketing_Banner {
    background-position: left center;
  }
  .FinTechCard {
    max-width: calc(50% - 10px);
  }
}
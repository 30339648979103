.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.NavBtns .active {
  border-bottom: 2px solid #ff853c;
  border-radius: 4px 4px 0 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.FooterBOX {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
  .FooterBOX {
    background-position: left center;
  }
}

.my-pages-enter {
  opacity: 0;
  transform: scale(0.99);
}
.my-pages-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.my-pages-exit {
  opacity: 1;
}
.my-pages-exit-active {
  opacity: 0;
  transform: scale(0.99);
  transition: opacity 300ms, transform 300ms;
}

.grecaptcha-badge {
  display: none;
}